<div class="menu__content__separator">
    <div class="menu__content__title">Placement</div>
    <ng-include src="'menu/sleevePlacementDisclaimer.html'"></ng-include>
    <tile-list option-data="sectionData.decoData.placement" tile-name="placement"></tile-list>
</div>

<div class="menu__content__separator">
    <div class="menu__content__title">Pattern</div>
    <tile-list option-data="sectionData.decoData.pattern" tile-name="fillPattern" tile-template="embed">></tile-list>
</div>

<div class="menu__content__separator">
    <div class="menu__content__title">Design Color 1</div>
    <tile-list option-data="sectionData.decoData.fill.color_1" tile-template="color"></tile-list>
</div>

<div class="menu__content__separator">
    <div class="menu__content__title">Design Color 2</div>
    <tile-list option-data="sectionData.decoData.fill.color_2" tile-template="color"></tile-list>
</div>
